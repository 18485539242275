import {
  DEFAULT_FONT_FAMILY,
  OrgCustomFontDescriptor,
  PublishedAppColors,
  getCustomFontFamilyName,
} from "@hex/common";
import chroma from "chroma-js";
import { darken, desaturate, lighten, rgba } from "polished";

import { OrgPublishedAppTheme } from "../generated/graphqlTypes.js";

import { PartialThemeVariables, Theme } from "./common/theme.js";
import { DARK_THEME, createDarkMode } from "./themes/darkTheme.js";
import { LIGHT_THEME, createLightMode } from "./themes/lightTheme.js";

function generateDerivedThemeOverrides({
  colors,
  fontDescriptor,
  fontType,
  isLight,
}: {
  colors: Partial<PublishedAppColors>;
  isLight: boolean;
  fontType: OrgPublishedAppTheme["styles"]["font"];
  fontDescriptor: OrgCustomFontDescriptor | null;
}): PartialThemeVariables {
  const overrides: PartialThemeVariables = {};
  if (colors.backgroundColor) {
    const baseColor = isLight
      ? darken(0.05, colors.backgroundColor)
      : lighten(0.05, colors.backgroundColor);
    const adjustmentFn = isLight ? darken : lighten;

    // Derive background colors
    overrides.BackgroundColor = {
      ...(overrides.BackgroundColor ?? {}),
      DEFAULT: colors.backgroundColor,
      MUTED: adjustmentFn(0.01, baseColor),
      DARK: adjustmentFn(0.01, baseColor),
      LOGIC: adjustmentFn(0.012, baseColor),
      OVERLAY: rgba(baseColor, 0.6),
    };

    // For now we also apply to border color - in the future we may want to allow users to specify a border color
    overrides.BorderColor = {
      ...(overrides.BorderColor ?? {}),
      // Button borders
      DEFAULT: isLight
        ? desaturate(0.15, adjustmentFn(0.09, baseColor))
        : desaturate(0.15, adjustmentFn(0.18, baseColor)),
      // Table grids, chart gridlines, dividers
      MUTED: isLight
        ? desaturate(0.15, adjustmentFn(0.05, baseColor))
        : desaturate(0.15, adjustmentFn(0.09, baseColor)),
    };

    // Derive font colors
    overrides.FontColor = {
      ...(overrides.FontColor ?? {}),
      DEFAULT: isLight ? "#000000" : "#ffffff",
      MUTED: desaturate(0.1, adjustmentFn(0.45, baseColor)),
      PLACEHOLDER: desaturate(0.15, adjustmentFn(0.28, baseColor)),
    };

    overrides.IconColor = desaturate(0.1, adjustmentFn(0.45, baseColor));
  }

  if (colors.accentColor) {
    overrides.BrandColor = colors.accentColor;
    overrides.IntentColors = {
      ...(overrides.IntentColors ?? {}),
      PRIMARY: colors.accentColor,
    };
  }

  const fontFamilyName = getCustomFontFamilyName(fontType, fontDescriptor);

  if (fontFamilyName) {
    overrides.FontFamily = {
      DEFAULT: `"${fontFamilyName}", ${DEFAULT_FONT_FAMILY}`,
    };
    overrides.LetterSpacing = {
      DISPLAY: "0",
      HEADING: "0",
      MARKDOWN_HEADING: "0",
    };
  }

  return overrides;
}

export function getCustomTheme(
  publishedAppTheme: Pick<OrgPublishedAppTheme, "styles" | "font">,
  mode: "light" | "dark",
): { customTheme: Theme; isLight: boolean } {
  const colors = publishedAppTheme.styles[mode];
  const isValidBackgroundColor = colors.backgroundColor
    ? chroma.valid(colors.backgroundColor)
    : false;
  if (!isValidBackgroundColor || !colors.backgroundColor) {
    return {
      customTheme: mode === "light" ? LIGHT_THEME : DARK_THEME,
      isLight: mode === "light",
    };
  }

  // If there's a background color, we use that to determine what base theme we should use, regardless of the user's system preference
  // This is because an admin could set a single theme for both light and dark mode (i.e. override the system pref)
  const isLight = chroma(colors.backgroundColor).luminance() > 0.4;
  const overrides = generateDerivedThemeOverrides({
    colors,
    isLight,
    fontDescriptor: publishedAppTheme.font?.fontDescriptor ?? null,
    fontType: publishedAppTheme.styles.font ?? "default",
  });

  return {
    customTheme: {
      ...(isLight ? createLightMode(overrides) : createDarkMode(overrides)),
      isCustomTheme: true,
    },
    isLight,
  };
}
